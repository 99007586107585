import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import menu from "../components/menu"
import Layout from '../components/layout';
import SectionDisplay from '../components/sectiondisplay';

export default ({ pageContext: { oer }}) => {

	const showAdditionals = !! oer.Additionals.length;

  return (
    <Layout>
    	<div className="obContainer">
	    	<Link to="/" className="closeX"><span>X</span></Link>
	    	<div style={{ "width": "90%" }}>
	    		<h1>{oer.Title}</h1>
	    	</div>	      
	      <p>von Ulrich Kaiser</p>
	      {oer.Supported?.map(s => 
	      	<div>
	      		<p>{s.Text}</p><img src={s.Image} />
	      	</div>
	      )}
	      <hr style={{ "margin": "40px 0" }}/>
	      <div className="downloadLinksContainer">{oer.Downloads?.map(download => {
  					return <a className="downloadLink" href={`${oer.Folderpath}${download.Link}`}>{download.Text}</a>
  				})}
	      </div>
	      {showAdditionals && <hr style={{ "margin": "40px 0" }}/> } 
	      {showAdditionals && <div>
	      	<p>Zusätzliche Materialien zur dieser Publikation:</p>
	      	<ul className="additionalList">
	      		{oer.Additionals.map(add => {
	      			return <li><a href={add.Link}>{add.Text}</a></li>
	      		})}
	      	</ul>	      	
	      </div>}
	      <hr style={{ "margin": "40px 0" }} />
	      {oer.Sections.map((section, index) => { 
	      	return <SectionDisplay key={index} section={section} folderpath={oer.Folderpath} />	
	      })}
   	</div>

      <Helmet>
        <title>{`OER | ${oer.Title}`}</title>
        <meta name="description" content={oer.Description} />
        <meta name="keywords" content={oer.Keywords} />
        <meta name="copyright" content={`CC BY-SA Ulrich Kaiser, ${oer.Year}`} />
      </Helmet>
  </Layout>
  )
}
